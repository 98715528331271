import axios from "axios";
import React, { useEffect, useState } from "react";
import Sidebar from "../../components/Sidebar";
import { API_AUTH } from "../../utils/BaseUrl";

function DAdmin() {
  const [dashboard, setDashboard] = useState({
    totalJenisObat: 0,
    totalObat: 0,
    totalPasienGuru: 0,
    totalPasienSiswa: 0,
  });
  const [periksa, setPeriksa] = useState([]);

  const getDash = async () => {
    await axios
      .get(`${API_AUTH}/api/dashboard`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setDashboard(res.data);
      })
      .catch((error) => {
        alert("Terjadi kesalahan " + error);
      });
  };

  const getPeriksa = async () => {
    await axios
      .get(`${API_AUTH}/api/periksa`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setPeriksa(res.data);
      })
      .catch((error) => {
        alert("Terjadi kesalahan " + error);
      });
  };

  useEffect(() => {
    getDash();
    getPeriksa();
  }, []);

  return (
    <div className="relative min-h-screen md:flex" data-dev-hint="container">
      <Sidebar />
      <main id="content" className="max-h-screen overflow-y-auto flex-1 p-6 lg:px-8">
        <div className="container mx-auto">
          <div className="grid gap-6 mb-2 mt-2 md:grid-cols-3">
            <div className="py-2 bg-white shadow border border-gray-900">
              <p className="text-md text-center font-medium">
                Daftar Pasien Guru
              </p>
              <div className="text-3xl text-center text-green-400 font-semibold mb-2">
                <span className="fa-stack fa-xs">
                  <i className="fa fa-circle fa-stack-2x"></i>
                  <i className="fa fa-wheelchair fa-stack-1x fa-inverse"></i>
                </span>
                {dashboard.totalPasienGuru} Guru
              </div>
            </div>

            <div className="py-2 bg-white shadow border border-gray-900">
              <p className="text-md text-center font-medium">
                Daftar Pasien Siswa
              </p>
              <div className="text-3xl text-center text-green-400 font-semibold mb-2">
                <span className="fa-stack fa-xs">
                  <i className="fa fa-circle fa-stack-2x"></i>
                  <i className="fa fa-wheelchair fa-stack-1x fa-inverse"></i>
                </span>
                {dashboard.totalPasienSiswa} Siswa
              </div>
            </div>

            <div className="py-2 bg-white shadow border border-gray-900">
              <p className="text-md text-center font-medium">
                Daftar Pasien Karyawan
              </p>
              <div className="text-3xl text-center text-green-400 font-semibold mb-2">
                <span className="fa-stack fa-xs">
                  <i className="fa fa-circle fa-stack-2x"></i>
                  <i className="fa fa-wheelchair fa-stack-1x fa-inverse"></i>
                </span>
                {dashboard.totalPasienKaryawan} Karyawan
              </div>
            </div>
          </div>

          <div className="text-center py-2.5 rounded-t-lg mt-4 bg-green-400 text-white text-xl">
            Riwayat Pasien
          </div>
          <div className="my-4 overflow-x-auto w-full px-4 bg-white rounded-b-lg shadow">
            <table className="w-full divide-y divide-gray-300 text-center">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-6 py-2 text-xs text-gray-500">No</th>
                  <th className="px-6 py-2 text-xs text-gray-500">
                    Nama Pasien
                  </th>
                  <th className="px-6 py-2 text-xs text-gray-500">
                    Status Pasien
                  </th>
                  <th className="px-6 py-2 text-xs text-gray-500">
                    Tanggal/Jam Periksa
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-300">
                {periksa.map((periksaa, index) => (
                  <tr key={periksaa.id} className="whitespace-nowrap">
                    <td className="px-6 py-4 text-sm text-gray-500">
                      {index + 1}
                    </td>
                    <td className="px-6 py-4">
                      <div className="text-sm text-gray-900">
                        {periksaa.namaPasien}
                      </div>
                    </td>
                    <td className="px-6 py-4">
                      <div className="text-sm text-gray-500 uppercase">
                        {periksaa.pasienStatusId?.name}
                      </div>
                    </td>
                    <td className="px-6 py-4">
                      <div className="text-sm text-gray-500">
                        {periksaa.createAt}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </main>
    </div>
  );
}

export default DAdmin;
