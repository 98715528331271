import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { API_AUTH } from "../utils/BaseUrl";

export default function Register() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const navigate = useNavigate();

  const register = async (e) => {
    e.preventDefault();

    try {
      const { status } = await axios.post(
        `${API_AUTH}/api/auth/register`,
        {
          password: password,
          username: username,
        }
      );
      if (status === 200) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Register Success!!",
          showConfirmButton: false,
          timer: 1500,
        });
        navigate("/");
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Register Error",
        text: "Username telah digunakan!",
      });
    }
  };

  return (
    <div className="relative w-full h-screen bg-zinc-900/90">
      <div className="flex justify-center items-center h-full">
        <form
          className="max-w-[400px] w-full mx-auto bg-white p-8"
          onSubmit={register}
        >
          <h2 className="text-4xl font-bold text-center py-8">REGISTER</h2>
          <div className="flex flex-col mb-3">
            <label htmlFor="username" className="text-2xl font-mono">
              Username
            </label>
            <input
              className="border relative bg-gray-100 p-2"
              type="text"
              defaultValue={username}
              onChange={(e) => setUsername(e.target.value)}
              name="username"
              id="username"
              required=""
            />
          </div>

          <div className="flex flex-col mb-4">
            <label htmlFor="password" className="text-2xl font-mono ">
              Password
            </label>
            <input
              className="border relative bg-gray-100 p-2"
              type="password"
              defaultValue={password}
              onChange={(e) => setPassword(e.target.value)}
              name="password"
              id="password"
              placeholder="Password"
              required=""
            />
          </div>
          <button
            type="submit"
            className="w-80 py-2 mt-1 ml-2 bg-indigo-600 hover:bg-indigo-500 relative text-white"
          >
            Register
          </button>
          <div className="justify-between">
            <p className="text-sm font-semibold ">
              Have account? back Sign In
              <a
                href="/register"
                className="text-red-600 hover:text-red-700 focus:text-red-700 transition duration-200 ease-in-out"
              >
                Create an account
              </a>
            </p>
          </div>
        </form>
      </div>
    </div>
  );
}
