import React, { useEffect, useState } from "react";
import Sidebar from "../../components/Sidebar";
import axios from "axios";
import Swal from "sweetalert2";
import { Modal, Button, Form, InputGroup } from "react-bootstrap";
import { API_AUTH } from "../../utils/BaseUrl";

function Diagnosa() {
  const [diagnosa, setDiagnosa] = useState([]);
  const [show, setShow] = useState(false);
  const [name, setName] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const getDiagnosa = async () => {
    await axios
      .get(`${API_AUTH}/api/diagnosa-penyakit`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setDiagnosa(res.data);
      })
      .catch((error) => {
        alert("Terjadi kesalahan" + error);
      });
  };

  const addDiagnosa = async (e) => {
    e.preventDefault();
    const data = {
      name: name,
    };
    await axios.post(`${API_AUTH}/api/diagnosa-penyakit/`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    Swal.fire({
      position: "center",
      icon: "success",
      title: "Add Success!!",
      showConfirmButton: false,
      timer: 1500,
    });
    getDiagnosa();
    handleClose();
  };

  const deleteDiagnosa = async (id) => {
    await axios
      .delete(`${API_AUTH}/api/diagnosa-penyakit/` + id, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then(() => {
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Delete Success!!",
          showConfirmButton: false,
          timer: 1500,
        });
        getDiagnosa();
      })
      .catch(() => {
        Swal.fire({
          icon: "error",
          title: "Kesalahan..",
          text: "Diagnosa penyakit tidak bisa dihapus!",
        });
      });
  };

  useEffect(() => {
    getDiagnosa();
  }, []);

  return (
    <div className="relative min-h-screen md:flex" data-dev-hint="container">
      <Sidebar />
      <main id="content" className="max-h-screen overflow-y-auto flex-1 p-6 lg:px-8">
        <div className="container mx-auto">
          <div className="grid grid-cols-1 px-2 md:grid-cols-3 rounded-t-lg py-2.5 bg-green-400 text-white text-xl">
            <div className="flex justify-center mb-2 md:justify-start md:pl-6">
              Diagnosa Penyakit
            </div>
            <div className="flex flex-wrap justify-center col-span-2 gap-2 md:justify-end">
              <Button
                className="py-1 float-end bg-sky-400
          text-white bg-sky-500 hover:bg-sky-600 focus:ring-4 focus:outline-none focus:ring-sky-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center w-[250px] md:w-[250px]"
                onClick={handleShow}
              >
                Tambah
              </Button>
            </div>
          </div>
          <div className="overflow-x-auto w-full px-4 bg-white rounded-b-lg shadow">
            <table className="my-4 w-full divide-y divide-gray-300 text-center">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-3 py-2 text-xs text-gray-500">NO</th>
                  <th className="px-3 py-2 text-xs text-gray-500">
                    NAMA DIAGNOSA
                  </th>
                  <th className="px-3 py-2 text-xs text-gray-500">AKSI</th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-300">
                {diagnosa.map((diagnosas, i) => (
                  <tr key={i} className="whitespace-nowrap">
                    <td className="px-3 py-4 text-sm text-gray-500">{i + 1}</td>
                    <td className="px-3 py-4">
                      <div className="text-sm text-gray-900">
                        {diagnosas.name}
                      </div>
                    </td>
                    <td className="flex gap-4 px-3 py-4 justify-content-center">
                      <div>
                        <a href={"/edit-diagnosa/" + diagnosas.id}>
                          <button>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="w-6 h-6 text-blue-400"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                              />
                            </svg>
                          </button>
                        </a>
                      </div>
                      <div>
                        <button onClick={() => deleteDiagnosa(diagnosas.id)}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="w-6 h-6 text-red-400"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                            />
                          </svg>
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <Modal show={show} onHide={handleClose} animation={false}>
          <Modal.Header closeButton>
            <Modal.Title>Tambah Diagnosa Penyakit</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={addDiagnosa}>
              <div className="name mb-3">
                <Form.Label>
                  <strong>Nama Penyakit</strong>
                </Form.Label>
                <InputGroup className="d-flex gap-3">
                  <Form.Control
                    placeholder="Nama Penyakit"
                    defaultValue={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </InputGroup>
              </div>
              <div className="d-flex justify-content-end align-items-center mt-2">
                <button
                  className="bg-red-600
                            text-white hover:bg-red-700 
                            focus:ring-4 focus:outline-none focus:ring-red-300 
                            font-medium rounded-lg text-md w-full sm:w-auto px-4 py-2 text-center"
                  onClick={handleClose}
                >
                  Batal
                </button>
                <div className="ms-2 me-2">||</div>
                <button
                  className="bg-green-500
                            text-white hover:bg-green-600 
                            focus:ring-4 focus:outline-none focus:ring-green-300 
                            font-medium rounded-lg text-md w-full sm:w-auto px-4 py-2 text-center"
                  type="submit"
                >
                  Simpan
                </button>
              </div>
            </Form>
          </Modal.Body>
        </Modal>
      </main>
    </div>
  );
}

export default Diagnosa;
