import React, { useEffect, useState } from "react";
import Sidebar from "../../components/Sidebar";
import { Modal, Button, Form, InputGroup } from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";
import { API_AUTH } from "../../utils/BaseUrl";
import Loading from "../../components/Loading";

function DaftarGuru() {
  const [show, setShow] = useState(false);
  const [showExcel, setShowExcel] = useState(false);
  const [guru, setGuru] = useState([]);
  const [namaGuru, setNamaGuru] = useState();
  const [tempatLahir, setTempatLahir] = useState();
  const [tanggalLahir, setTanggalLahir] = useState();
  const [alamat, setAlamat] = useState();
  const [excel, setExcel] = useState("");

  const [loading, setLoading] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleCloseExcel = () => setShowExcel(false);
  const handleShowExcel = () => setShowExcel(true);

  const addGuru = async (e) => {
    e.preventDefault();

    const data = {
      namaGuru: namaGuru,
      tempatLahir: tempatLahir,
      tanggalLahir: tanggalLahir,
      alamat: alamat,
    };

    try {
      await axios.post(`${API_AUTH}/api/guru/guru`, data);
      Swal.fire({
        position: "center",
        icon: "success",
        title: "Add Success!!",
        showConfirmButton: false,
        timer: 1500,
      });
      handleClose();
      getGuru();
    } catch (error) {
      console.log(error);
    }
  };

  const getGuru = async () => {
    await axios
      .get(`${API_AUTH}/api/guru/guru`)
      .then((res) => {
        setGuru(res.data);
      })
      .catch((error) => {
        alert("Terjadi kesalahan " + error);
      });
  };

  const importGuruFromExcel = async (e) => {
    e.preventDefault();

    const formData = new FormData();

    formData.append("file", excel);

    await axios
      .post(`${API_AUTH}/api/excel/upload/guru`, formData)
      .then(() => {
        handleCloseExcel();
        getGuru();
        Swal.fire("Sukses!", " berhasil ditambahkan.", "success");
      })
      .catch((err) => {
        console.log(err);
        Swal.fire("Error", "Anda belum memilih file untuk diimport!.", "error");
      });
  };

  const downloadFormat = async () => {
    await Swal.fire({
      title: "Yakin ingin mendownload?",
      text: "Ini adalah file format excel untuk mengimport data.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#0b409c",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ya, download!",
      cancelButtonText: "Batal",
    }).then((result) => {
      if (result.isConfirmed) {
        axios({
          url: `${API_AUTH}/api/excel/download/format-guru`,
          method: "GET",
          responseType: "blob",
        }).then((response) => {
          setLoading(true);
          setTimeout(() => {
            var fileURL = window.URL.createObjectURL(new Blob([response.data]));
            var fileLink = document.createElement("a");

            fileLink.href = fileURL;
            fileLink.setAttribute("download", "format-guru.xlsx");
            document.body.appendChild(fileLink);

            fileLink.click();
            handleCloseExcel();
            setLoading(false);
          }, 2000);
        });
      }
    });
  };

  const downloadDataGuru = async () => {
    await Swal.fire({
      title: "Yakin ingin mendownload?",
      text: "File berisi semua data guru.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#0b409c",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ya, download!",
      cancelButtonText: "Batal",
    }).then((result) => {
      if (result.isConfirmed) {
        axios({
          url: `${API_AUTH}/api/excel/download/data-guru`,
          method: "GET",
          responseType: "blob",
        }).then((response) => {
          setLoading(true);
          setTimeout(() => {
            var fileURL = window.URL.createObjectURL(new Blob([response.data]));
            var fileLink = document.createElement("a");

            fileLink.href = fileURL;
            fileLink.setAttribute("download", "guru.xlsx");
            document.body.appendChild(fileLink);

            fileLink.click();
            setLoading(false);
          }, 2000);
        });
      }
    });
  };

  const deleteGuru = async (id) => {
    try {
      await axios.delete(`${API_AUTH}/api/guru/guru/` + id);
      Swal.fire({
        position: "center",
        icon: "success",
        title: "Delete Success!!",
        showConfirmButton: false,
        timer: 1500,
      });
      getGuru();
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Gagal!",
        text: "Guru tidak bisa di hapus karena sudah periksa",
      });
    }
  };

  useEffect(() => {
    getGuru();
  }, []);

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div
          className="relative min-h-screen md:flex"
          data-dev-hint="container"
        >
          <Sidebar />
          <main id="content" className="max-h-screen overflow-y-auto flex-1 p-6 lg:px-8">
            <div className="container mx-auto">
              <div className="grid grid-cols-1 px-2 md:grid-cols-3 rounded-t-lg py-2.5 bg-green-400 text-white text-xl">
                <div className="flex justify-center mb-2 md:justify-start md:pl-6">
                  Daftar Guru
                </div>
                <div className="flex flex-wrap justify-center col-span-2 gap-2 md:justify-end">
                  <Button
                    className="py-1 float-end bg-sky-400
          text-white bg-sky-500 hover:bg-sky-600 focus:ring-4 focus:outline-none focus:ring-sky-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center w-[250px] md:w-[250px]"
                    onClick={handleShow}
                  >
                    Tambah
                  </Button>
                  <Button
                    className="py-1 float-end bg-sky-400
          text-white bg-sky-500 hover:bg-sky-600 focus:ring-4 focus:outline-none focus:ring-sky-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center w-[250px] md:w-[250px]"
                    onClick={handleShowExcel}
                  >
                    Import Data
                  </Button>
                  <Button
                    className="py-1 float-end bg-sky-400
          text-white bg-sky-500 hover:bg-sky-600 focus:ring-4 focus:outline-none focus:ring-sky-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center w-[250px] md:w-[250px]"
                    onClick={downloadDataGuru}
                  >
                    Download Data
                  </Button>
                </div>
              </div>
              <div className="overflow-x-auto w-full px-4 bg-white rounded-b-lg shadow">
                <table className="my-4 w-full divide-y divide-gray-300 text-center">
                  <thead className="bg-gray-50">
                    <tr>
                      <th className="px-3 py-2 text-xs text-gray-500">NO</th>
                      <th className="px-3 py-2 text-xs text-gray-500">
                        NAMA GURU
                      </th>
                      <th className="px-3 py-2 text-xs text-gray-500">
                        TEMPAT TANGGAL LAHIR
                      </th>
                      <th className="px-3 py-2 text-xs text-gray-500">
                        ALAMAT
                      </th>
                      <th className="px-3 py-2 text-xs text-gray-500">AKSI</th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-300">
                    {guru.map((guruu, index) => (
                      <tr key={guruu.id} className="whitespace-nowrap">
                        <td className="px-3 py-4 text-sm text-gray-500">
                          {index + 1}
                        </td>
                        <td className="px-3 py-4">
                          <div className="text-sm text-gray-900">
                            {guruu.namaGuru}
                          </div>
                        </td>
                        <td className="px-3 py-4">
                          <div className="text-sm text-gray-500">
                            {guruu.tempatLahir && guruu.tanggalLahir
                              ? guruu.tempatLahir && guruu.tanggalLahir
                              : "-"}
                          </div>
                        </td>
                        <td className="px-3 py-4 text-sm text-gray-500">
                          <div className="text-sm text-gray-500">
                            {guruu.alamat ? guruu.alamat : "-"}
                          </div>
                        </td>
                        <td className="flex justify-content-center gap-4 px-3 py-4">
                          <div>
                            <a href={"/edit-guru/" + guruu.id}>
                              <button>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="w-6 h-6 text-blue-400"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                                  />
                                </svg>
                              </button>
                            </a>
                          </div>
                          <div>
                            <button onClick={() => deleteGuru(guruu.id)}>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="w-6 h-6 text-red-400"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                                />
                              </svg>
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <Modal show={show} onHide={handleClose} animation={false}>
              <Modal.Header closeButton>
                <Modal.Title>Tambah Guru</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form onSubmit={addGuru}>
                  <div className="name mb-3">
                    <Form.Label>
                      <strong>Nama Guru</strong>
                    </Form.Label>
                    <InputGroup className="d-flex gap-3">
                      <Form.Control
                        placeholder="Nama Guru"
                        defaultValue={namaGuru}
                        onChange={(e) => setNamaGuru(e.target.value)}
                        required
                      />
                    </InputGroup>
                  </div>
                  <div className="name mb-3">
                    <Form.Label>
                      <strong>Tempat Lahir</strong>
                    </Form.Label>
                    <InputGroup className="d-flex gap-3">
                      <Form.Control
                        placeholder="Tempat Lahir"
                        defaultValue={tempatLahir}
                        onChange={(e) => setTempatLahir(e.target.value)}
                      />
                    </InputGroup>
                  </div>
                  <div className="name mb-3">
                    <Form.Label>
                      <strong>Tanggal Lahir</strong>
                    </Form.Label>
                    <InputGroup className="d-flex gap-3">
                      <Form.Control
                        type="date"
                        defaultValue={tanggalLahir}
                        onChange={(e) => setTanggalLahir(e.target.value)}
                      />
                    </InputGroup>
                  </div>
                  <div className="name mb-3">
                    <Form.Label>
                      <strong>Alamat</strong>
                    </Form.Label>
                    <InputGroup className="d-flex gap-3">
                      <Form.Control
                        placeholder="Alamat"
                        defaultValue={alamat}
                        onChange={(e) => setAlamat(e.target.value)}
                      />
                    </InputGroup>
                  </div>
                  <div className="d-flex justify-content-end align-items-center mt-2">
                    <button
                      className="bg-red-600
              text-white hover:bg-red-700 
              focus:ring-4 focus:outline-none focus:ring-red-300 
              font-medium rounded-lg text-md w-full sm:w-auto px-4 py-2 text-center"
                      onClick={handleClose}
                    >
                      Batal
                    </button>
                    <div className="ms-2 me-2">||</div>
                    <button
                      className="bg-green-500
              text-white hover:bg-green-600 
              focus:ring-4 focus:outline-none focus:ring-green-300 
              font-medium rounded-lg text-md w-full sm:w-auto px-4 py-2 text-center"
                      type="submit"
                    >
                      Simpan
                    </button>
                  </div>
                </Form>
              </Modal.Body>
            </Modal>
            <Modal show={showExcel} onHide={handleCloseExcel} animation={false}>
              <Modal.Header closeButton>
                <Modal.Title>Import Guru Dari File Excel</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="mb-3 p-2 rounded-4 border border-dashed">
                  <p className="text-center">
                    download file dibawah untuk menginput data guru anda.
                    <br />{" "}
                    <span className="font-bold">
                      ( *column tanggal lahir diubah menjadi short date )
                    </span>
                  </p>
                  <div className=" flex justify-center">
                    <Button
                      className="py-1 bg-sky-400
          text-white bg-sky-500 hover:bg-sky-600 focus:ring-4 focus:outline-none focus:ring-sky-300 font-medium rounded-lg text-sm w-full sm:w-auto text-center"
                      onClick={downloadFormat}
                    >
                      Download Format File
                    </Button>
                  </div>
                </div>
                <Form onSubmit={importGuruFromExcel}>
                  <div className="mb-3">
                    <Form.Label>
                      <strong className="text-lg">Drop File.xlsx</strong>
                    </Form.Label>
                    <InputGroup>
                      <Form.Control
                        required
                        type="file"
                        accept=".xlsx"
                        onChange={(e) => setExcel(e.target.files[0])}
                      />
                    </InputGroup>
                  </div>
                  <div className="d-flex justify-content-end align-items-center mt-2">
                    <button
                      className="bg-red-600
              text-white hover:bg-red-700 
              focus:ring-4 focus:outline-none focus:ring-red-300 
              font-medium rounded-lg text-md w-full sm:w-auto px-4 py-2 text-center"
                      onClick={handleCloseExcel}
                    >
                      Batal
                    </button>
                    <div className="ms-2 me-2">||</div>
                    <button
                      className="bg-green-500
              text-white hover:bg-green-600 
              focus:ring-4 focus:outline-none focus:ring-green-300 
              font-medium rounded-lg text-md w-full sm:w-auto px-4 py-2 text-center"
                      type="submit"
                    >
                      Simpan
                    </button>
                  </div>
                </Form>
              </Modal.Body>
            </Modal>
          </main>
        </div>
      )}
    </>
  );
}

export default DaftarGuru;
