import React, { useEffect, useRef, useState } from "react";
import Sidebar from "../../components/Sidebar";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Modal, Button, Form, InputGroup } from "react-bootstrap";
import Swal from "sweetalert2";
import Loading from "../../components/Loading";
import { API_AUTH } from "../../utils/BaseUrl";
import { useDownloadExcel } from "react-export-table-to-excel";

function DaftarSiswa() {
  const [periksa, setPeriksa] = useState([]);
  const [statusPasien] = useState([
    { key: "Guru", name: "Guru" },
    { key: "Siswa", name: "Siswa" },
    { key: "Karyawan", name: "Karyawan" },
  ]);
  const [statusPasienId, setStatusPasienId] = useState(null);
  const [rekapData, setRekapData] = useState([]);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const [loading, setLoading] = useState(false);
  const [pasien, setPasien] = useState([]);
  const [pasienId, setPasienId] = useState(null);
  const [keluhan, setKeluhan] = useState();

  const [showRekapData, setShowRekapData] = useState(false);
  const [show, setShow] = useState(false);
  const [showFilter, setShowFilter] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleCloseFilter = () => setShowFilter(false);
  const handleShowFilter = () => setShowFilter(true);
  const handleShowRekapData = () => setShowRekapData(true);

  const [downloadData, setDownloadData] = useState([]);
  const tableRef = useRef(null);

  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();

    axios
      .get(
        `${API_AUTH}/api/periksa/rekap-data/semester-gasal?endDate=${endDate}&startDate=${startDate}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        setRekapData(res.data.data);
        handleShowRekapData();
        handleCloseFilter();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const downloadExcel = async () => {
    setLoading(true);
    const header = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    const response = await axios.get(
      `${API_AUTH}/api/periksa/rekap-data/semester-gasal?endDate=${endDate}&startDate=${startDate}`,
      header
    );
    setDownloadData(response.data);
    setTimeout(() => {
      onDownload();
      setLoading(false);
    }, 1000);
  };

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "Data Periksa " + new Date(),
    sheet: "UKS",
  });

  const download = async (id) => {
    setLoading(true);
    await axios({
      url: `${API_AUTH}/api/excel/download/${id}`,
      method: "GET",
      responseType: "blob",
    }).then((response) => {
      setTimeout(() => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement("a");
        fileLink.href = fileURL;
        fileLink.setAttribute("download", "periksa.xlsx");
        document.body.appendChild(fileLink);
        fileLink.click();
        setLoading(false);
      }, 2000);
    });
  };

  const getPeriksa = async () => {
    await axios
      .get(`${API_AUTH}/api/periksa`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setPeriksa(res.data);
      })
      .catch((error) => {
        alert("Terjadi kesalahan " + error);
      });
  };

  const getListSiswa = async () => {
    const siswa = await axios.get(`${API_AUTH}/api/siswa/siswa`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    setPasien(siswa.data);
  };

  const getListKaryawan = async () => {
    const karyawan = await axios.get(`${API_AUTH}/api/karyawan`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    setPasien(karyawan.data);
  };

  const getListGuru = async () => {
    const guru = await axios.get(`${API_AUTH}/api/guru/guru`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    setPasien(guru.data);
  };

  const addPeriksa = async (e) => {
    e.preventDefault();

    const data = {
      keluhan: keluhan,
      pasienId: pasienId,
      status: statusPasienId,
    };

    try {
      await axios.post(`${API_AUTH}/api/periksa`, data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      Swal.fire({
        position: "center",
        icon: "success",
        title: "Add Success!!",
        showConfirmButton: false,
        timer: 1500,
      });
      getPeriksa();
      handleClose();
    } catch (error) {
      console.log(error);
    }
  };

  const changeStatusPasien = (e) => {
    setStatusPasienId(e.target.value);

    if (e.target.value === "Siswa") {
      getListSiswa();
    } else if (e.target.value === "Karyawan") {
      getListKaryawan();
    } else {
      getListGuru();
    }
  };

  const penanganan = (e, id) => {
    if (e) return;
    navigate("/status-periksa/" + id);
  };
  useEffect(() => {
    getPeriksa();
  }, []);

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div
          className="relative min-h-screen md:flex"
          data-dev-hint="container"
        >
          <Sidebar />
          <main id="content" className="max-h-screen overflow-y-auto flex-1 p-6 lg:px-8">
            <div className="container mx-auto">
              <div className="grid grid-cols-1 px-2 md:grid-cols-3 py-2.5 bg-green-400 text-white text-xl rounded-t-lg mt-4">
                <div className="flex justify-center mb-2 md:justify-start md:pl-6">
                  Filter Rekap Data
                </div>
                <div className="flex flex-wrap justify-center col-span-2 gap-2 md:justify-end">
                  <Button
                    className="py-1 float-end bg-sky-400
          text-white bg-sky-500 hover:bg-sky-600 focus:ring-4 focus:outline-none focus:ring-sky-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center w-[200px] md:w-[200px]"
                    onClick={handleShowFilter}
                  >
                    Filter Tanggal
                  </Button>
                </div>
              </div>
              <div className="w-full px-4 bg-white rounded-b-lg shadow">
                {showRekapData ? (
                  <div className="grid mb-2 py-6 md:grid-cols-1">
                    <p className="text-4xl text-center font-medium">
                      Rekap Data Pasien <br />{" "}
                      <span className="text-xl">
                        {" "}
                        ({startDate}) - ({endDate})
                      </span>
                    </p>
                    <div className="flex justify-center my-2">
                      <Button
                        className="py-2 bg-sky-400
          text-white bg-sky-500 hover:bg-sky-600 focus:ring-4 focus:outline-none focus:ring-sky-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center w-full"
                        onClick={() => downloadExcel()}
                      >
                        Download Rekap Data Pasien
                      </Button>
                    </div>
                  </div>
                ) : (
                  <section className="flex items-center h-full ">
                    <div className="container flex flex-col items-center justify-center px-5 mx-auto my-8 space-y-8 text-center sm:max-w-md">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                        className="w-40 h-40 dark:text-gray-600"
                      >
                        <path
                          fill="currentColor"
                          d="M256,16C123.452,16,16,123.452,16,256S123.452,496,256,496,496,388.548,496,256,388.548,16,256,16ZM403.078,403.078a207.253,207.253,0,1,1,44.589-66.125A207.332,207.332,0,0,1,403.078,403.078Z"
                        ></path>
                        <rect
                          width="176"
                          height="32"
                          x="168"
                          y="320"
                          fill="currentColor"
                        ></rect>
                        <polygon
                          fill="currentColor"
                          points="210.63 228.042 186.588 206.671 207.958 182.63 184.042 161.37 162.671 185.412 138.63 164.042 117.37 187.958 141.412 209.329 120.042 233.37 143.958 254.63 165.329 230.588 189.37 251.958 210.63 228.042"
                        ></polygon>
                        <polygon
                          fill="currentColor"
                          points="383.958 182.63 360.042 161.37 338.671 185.412 314.63 164.042 293.37 187.958 317.412 209.329 296.042 233.37 319.958 254.63 341.329 230.588 365.37 251.958 386.63 228.042 362.588 206.671 383.958 182.63"
                        ></polygon>
                      </svg>
                      <p className="text-3xl">
                        Filter terlebih dahulu sesuai tanggal yang diinginkan.
                      </p>
                    </div>
                  </section>
                )}
              </div>

              <div className="grid grid-cols-1 px-2 md:grid-cols-3 py-2.5 bg-green-400 text-white text-xl rounded-t-lg mt-4">
                <div className="flex justify-center mb-2 md:justify-start md:pl-6">
                  Daftar Pasien
                </div>
                <div className="flex flex-wrap justify-center col-span-2 gap-2 md:justify-end">
                  <Button
                    className="py-1 float-end bg-sky-400
          text-white bg-sky-500 hover:bg-sky-600 focus:ring-4 focus:outline-none focus:ring-sky-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center w-[200px] md:w-[200px]"
                    onClick={handleShow}
                  >
                    Tambah
                  </Button>
                </div>
              </div>
              <div className="overflow-x-auto w-full px-4 bg-white rounded-b-lg shadow">
                <table className="my-4 w-full divide-y divide-gray-300 text-center">
                  <thead className="bg-gray-50">
                    <tr>
                      <th className="px-2 py-2 text-xs text-gray-500">NO</th>
                      <th className="px-2 py-2 text-xs text-gray-500">
                        NAMA PASIEN
                      </th>
                      <th className="px-2 py-2 text-xs text-gray-500">
                        STATUS PASIEN
                      </th>
                      <th className="px-2 py-2 text-xs text-gray-500">
                        JABATAN
                      </th>
                      <th className="px-2 py-2 text-xs text-gray-500">TANGGAL / JAM</th>
                      <th className="px-2 py-2 text-xs text-gray-500">
                        KETERANGAN
                      </th>
                      <th className="px-2 py-2 text-xs text-gray-500">
                        STATUS
                      </th>
                      <th className="px-2 py-2 text-xs text-gray-500">AKSI</th>
                    </tr>
                  </thead>
                  {showRekapData ? (
                    <tbody className="bg-white divide-y divide-gray-300">
                      {rekapData.data.map((periksaa, index) => (
                        <tr key={periksaa.id} className="whitespace-nowrap">
                          <td className="px-2 py-3 text-sm text-gray-500">
                            {index + 1}
                          </td>
                          <td className="px-2 py-3">
                            <div className="text-sm text-gray-900">
                              {periksaa.namaPasien}
                            </div>
                          </td>
                          <td className="px-2 py-3">
                            <div className="text-sm text-gray-500">
                              {periksaa.pasienStatusId.name}
                            </div>
                          </td>
                          <td className="px-2 py-3 text-sm text-gray-500">
                            <div className="text-sm text-gray-500">
                              {periksaa.siswaId
                                ? periksaa.siswaId.kelas
                                : periksaa.guruId
                                ? "Guru"
                                : "Karyawan"}
                            </div>
                          </td>
                          <td className="px-2 py-3">
                            <div className="text-sm text-gray-500">
                              {periksaa.createAt}
                            </div>
                          </td>
                          <td className="px-2 py-3">
                            {periksaa.handled
                              ? periksaa.listPenanganan.map((list, index) => (
                                  <div
                                    key={index}
                                    className="text-sm text-gray-500"
                                  >
                                    {list.keteraganPasienId
                                      ? list.keteraganPasienId.name
                                      : "-"}
                                  </div>
                                ))
                              : "-"}
                          </td>
                          <td className="px-2 py-3">
                            <div
                              className={
                                periksaa.handled
                                  ? `text-sm text-green-500`
                                  : "text-sm text-red-500"
                              }
                            >
                              {periksaa.handled
                                ? "Sudah Ditangani"
                                : "Belum Ditangani"}
                            </div>
                          </td>
                          <td className="px-2 py-3">
                            {periksaa.handled && (
                              <button
                                className="mr-4"
                                title="Download Pasien Excel"
                                onClick={() => download(periksaa.id)}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="25"
                                  height="25"
                                  fill="currentColor"
                                  className="bi bi-cloud-download"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M4.406 1.342A5.53 5.53 0 0 1 8 0c2.69 0 4.923 2 5.166 4.579C14.758 4.804 16 6.137 16 7.773 16 9.569 14.502 11 12.687 11H10a.5.5 0 0 1 0-1h2.688C13.979 10 15 8.988 15 7.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 2.825 10.328 1 8 1a4.53 4.53 0 0 0-2.941 1.1c-.757.652-1.153 1.438-1.153 2.055v.448l-.445.049C2.064 4.805 1 5.952 1 7.318 1 8.785 2.23 10 3.781 10H6a.5.5 0 0 1 0 1H3.781C1.708 11 0 9.366 0 7.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383z" />
                                  <path d="M7.646 15.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 14.293V5.5a.5.5 0 0 0-1 0v8.793l-2.146-2.147a.5.5 0 0 0-.708.708l3 3z" />
                                </svg>
                              </button>
                            )}
                            <button
                              onClick={() =>
                                penanganan(periksaa.handled, periksaa.id)
                              }
                              className={
                                periksaa.handled
                                  ? "text-white disabled bg-green-500 hover:bg-green-600 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm sm:w-auto mr-2 px-3 py-2 text-center float-end no-underline hover:no-underline"
                                  : "text-white bg-red-500 hover:bg-red-600 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm sm:w-auto mr-2 px-3 py-2 text-center float-end no-underline hover:no-underline"
                              }
                            >
                              {periksaa.handled ? "Selesai" : "Tangani"}
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  ) : (
                    <tbody className="bg-white divide-y divide-gray-300">
                      {periksa.map((periksaa, index) => (
                        <tr key={periksaa.id} className="whitespace-nowrap">
                          <td className="px-2 py-3 text-sm text-gray-500">
                            {index + 1}
                          </td>
                          <td className="px-2 py-3">
                            <div className="text-sm text-gray-900">
                              {periksaa.namaPasien}
                            </div>
                          </td>
                          <td className="px-2 py-3">
                            <div className="text-sm text-gray-500">
                              {periksaa.pasienStatusId.name}
                            </div>
                          </td>
                          <td className="px-2 py-3 text-sm text-gray-500">
                            <div className="text-sm text-gray-500">
                              {periksaa.siswaId
                                ? periksaa.siswaId.kelas
                                : periksaa.guruId
                                ? "Guru"
                                : "Karyawan"}
                            </div>
                          </td>
                          <td className="px-2 py-3">
                            <div className="text-sm text-gray-500">
                              {periksaa.createAt}
                            </div>
                          </td>
                          <td className="px-2 py-3">
                            {periksaa.handled
                              ? periksaa.listPenanganan.map((list, index) => (
                                  <div
                                    key={index}
                                    className="text-sm text-gray-500"
                                  >
                                    {list.keteraganPasienId
                                      ? list.keteraganPasienId.name
                                      : "-"}
                                  </div>
                                ))
                              : "-"}
                          </td>
                          <td className="px-2 py-3">
                            <div
                              className={
                                periksaa.handled
                                  ? `text-sm text-green-500`
                                  : "text-sm text-red-500"
                              }
                            >
                              {periksaa.handled
                                ? "Sudah Ditangani"
                                : "Belum Ditangani"}
                            </div>
                          </td>
                          <td className="px-2 py-3">
                            {periksaa.handled && (
                              <button
                                className="mr-4"
                                title="Download Pasien Excel"
                                onClick={() => download(periksaa.id)}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="25"
                                  height="25"
                                  fill="currentColor"
                                  className="bi bi-cloud-download"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M4.406 1.342A5.53 5.53 0 0 1 8 0c2.69 0 4.923 2 5.166 4.579C14.758 4.804 16 6.137 16 7.773 16 9.569 14.502 11 12.687 11H10a.5.5 0 0 1 0-1h2.688C13.979 10 15 8.988 15 7.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 2.825 10.328 1 8 1a4.53 4.53 0 0 0-2.941 1.1c-.757.652-1.153 1.438-1.153 2.055v.448l-.445.049C2.064 4.805 1 5.952 1 7.318 1 8.785 2.23 10 3.781 10H6a.5.5 0 0 1 0 1H3.781C1.708 11 0 9.366 0 7.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383z" />
                                  <path d="M7.646 15.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 14.293V5.5a.5.5 0 0 0-1 0v8.793l-2.146-2.147a.5.5 0 0 0-.708.708l3 3z" />
                                </svg>
                              </button>
                            )}
                            <button
                              onClick={() =>
                                penanganan(periksaa.handled, periksaa.id)
                              }
                              className={
                                periksaa.handled
                                  ? "text-white disabled bg-green-500 hover:bg-green-600 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm sm:w-auto mr-2 px-3 py-2 text-center float-end no-underline hover:no-underline"
                                  : "text-white bg-red-500 hover:bg-red-600 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm sm:w-auto mr-2 px-3 py-2 text-center float-end no-underline hover:no-underline"
                              }
                            >
                              {periksaa.handled ? "Selesai" : "Tangani"}
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  )}
                </table>
              </div>
            </div>
            <Modal show={show} onHide={handleClose} animation={false}>
              <Modal.Header closeButton>
                <Modal.Title>Add Daftar Pasien</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form onSubmit={addPeriksa}>
                  <div className="name mb-3">
                    <Form.Label>
                      <strong>Status Pasien</strong>
                    </Form.Label>
                    <Form.Select
                      onChange={(e) => changeStatusPasien(e)}
                      aria-label="Status"
                    >
                      <option className="hidden">Pilih Status</option>
                      {statusPasien.map((stat, index) => (
                        <option key={index} value={stat.key}>
                          {stat.name}
                        </option>
                      ))}
                    </Form.Select>
                  </div>
                  <div className="name mb-3">
                    <Form.Label>
                      <strong>Nama Pasien</strong>
                    </Form.Label>
                    <Form.Select
                      disabled={statusPasienId === null}
                      onChange={(e) => setPasienId(e.target.value)}
                      aria-label="Nama"
                    >
                      <option className="hidden">Pilih Nama</option>
                      {pasien.map((stat, index) => (
                        <option key={index} value={stat.id}>
                          {stat.namaSiswa
                            ? stat.namaSiswa
                            : stat.namaKaryawan
                            ? stat.namaKaryawan
                            : stat.namaGuru}
                        </option>
                      ))}
                    </Form.Select>
                  </div>
                  <div className="name mb-3">
                    <Form.Label>
                      <strong>Keluhan Pasien</strong>
                    </Form.Label>
                    <InputGroup className="d-flex gap-3">
                      <Form.Control
                        placeholder="Keluhan Pasien"
                        value={keluhan}
                        onChange={(e) => setKeluhan(e.target.value)}
                      />
                    </InputGroup>
                  </div>
                  <div className="d-flex justify-content-end align-items-center mt-2">
                    <button
                      className="bg-red-600
                                    text-white hover:bg-red-700 
                                    focus:ring-4 focus:outline-none focus:ring-red-300 
                                    font-medium rounded-lg text-md w-full sm:w-auto px-4 py-2 text-center"
                      onClick={handleClose}
                    >
                      Batal
                    </button>
                    <div className="ms-2 me-2">||</div>
                    <button
                      className="bg-green-500
                                    text-white hover:bg-green-600 
                                    focus:ring-4 focus:outline-none focus:ring-green-300 
                                    font-medium rounded-lg text-md w-full sm:w-auto px-4 py-2 text-center"
                      type="submit"
                    >
                      Simpan
                    </button>
                  </div>
                </Form>
              </Modal.Body>
            </Modal>
            {/* MODAL FILTER */}
            <Modal
              show={showFilter}
              onHide={handleCloseFilter}
              animation={false}
            >
              <Modal.Header closeButton>
                <Modal.Title>Filter Rekap Data Pasien</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form onSubmit={handleSubmit}>
                  <div className="name mb-3">
                    <Form.Label>
                      <strong>Dari Tanggal :</strong>
                    </Form.Label>
                    <InputGroup className="d-flex gap-3">
                      <Form.Control
                        type="date"
                        onChange={(e) => setStartDate(e.target.value)}
                      />
                    </InputGroup>
                  </div>
                  <div className="name mb-3">
                    <Form.Label>
                      <strong>Sampai Tanggal :</strong>
                    </Form.Label>
                    <InputGroup className="d-flex gap-3">
                      <Form.Control
                        type="date"
                        onChange={(e) => setEndDate(e.target.value)}
                      />
                    </InputGroup>
                  </div>
                  <div className="d-flex justify-content-end align-items-center mt-2">
                    <button
                      className="bg-red-600
                                    text-white hover:bg-red-700 
                                    focus:ring-4 focus:outline-none focus:ring-red-300 
                                    font-medium rounded-lg text-md w-full sm:w-auto px-4 py-2 text-center"
                      onClick={handleCloseFilter}
                    >
                      Batal
                    </button>
                    <div className="ms-2 me-2">||</div>
                    <button
                      className="bg-green-500
                                    text-white hover:bg-green-600 
                                    focus:ring-4 focus:outline-none focus:ring-green-300 
                                    font-medium rounded-lg text-md w-full sm:w-auto px-4 py-2 text-center"
                      type="submit"
                    >
                      Simpan
                    </button>
                  </div>
                </Form>
              </Modal.Body>
            </Modal>
            {/* Download to excel */}
            {showRekapData ? (
              <table
                className="w-full divide-y divide-gray-300 text-center hidden"
                ref={tableRef}
              >
                <thead className="bg-gray-50">
                  <tr>
                    <th className="px-2 py-2 text-xs text-gray-500">
                      Nama Pasien
                    </th>
                    <th className="px-2 py-2 text-xs text-gray-500">
                      Status Pasien
                    </th>
                    <th className="px-2 py-2 text-xs text-gray-500">Jabatan</th>
                    <th className="px-2 py-2 text-xs text-gray-500">Tanggal</th>
                    <th className="px-2 py-2 text-xs text-gray-500">
                      Keterangan
                    </th>
                    <th className="px-2 py-2 text-xs text-gray-500">Status</th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-300">
                  {rekapData.data.map((periksaa) => (
                    <tr key={periksaa.id} className="whitespace-nowrap">
                      <td className="px-2 py-3">
                        <div className="text-sm text-gray-900">
                          {periksaa.namaPasien}
                        </div>
                      </td>
                      <td className="px-2 py-3">
                        <div className="text-sm text-gray-500">
                          {periksaa.pasienStatusId.name}
                        </div>
                      </td>
                      <td className="px-2 py-3 text-sm text-gray-500">
                        <div className="text-sm text-gray-500">
                          {periksaa.siswaId
                            ? periksaa.siswaId.kelas
                            : periksaa.guruId
                            ? "Guru"
                            : "Karyawan"}
                        </div>
                      </td>
                      <td className="px-2 py-3">
                        <div className="text-sm text-gray-500">
                          {periksaa.createAt}
                        </div>
                      </td>
                      <td className="px-2 py-3">
                        {periksaa.handled
                          ? periksaa.listPenanganan.map((list, index) => (
                              <div
                                key={index}
                                className="text-sm text-gray-500"
                              >
                                {list.keteraganPasienId
                                  ? list.keteraganPasienId.name
                                  : "-"}
                              </div>
                            ))
                          : "-"}
                      </td>
                      <td className="px-2 py-3">
                        <div
                          className={
                            periksaa.handled
                              ? `text-sm text-green-500`
                              : "text-sm text-red-500"
                          }
                        >
                          {periksaa.handled
                            ? "Sudah Ditangani"
                            : "Belum Ditangani"}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : null}
          </main>
        </div>
      )}
    </>
  );
}

export default DaftarSiswa;
