import {
  Login,
  DAdmin,
  DaObat,
  DaftarGuru,
  DaftarSiswa,
  Periksa,
  EditSiswa,
  EditGuru,
  StatusPeriksa,
  EditObat,
  Diagnosa,
  EditDiagnosa,
  Register,
  Tindakan,
  EditTindakan,
  PenangananPertama,
  EditPenanganan,
  DaftarKaryawan,
  EditKaryawan,
} from "./pages";
import "bootstrap/dist/css/bootstrap.min.css";
import { Routes, Route } from "react-router-dom";
import PrivateRouter from "./utils/PrivateRouter";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route
          path="/dash"
          element={
            <PrivateRouter>
              <DAdmin />
            </PrivateRouter>
          }
        />
        <Route
          path="/daftar-obat"
          element={
            <PrivateRouter>
              <DaObat />
            </PrivateRouter>
          }
        />
        <Route
          path="/daftar-guru"
          element={
            <PrivateRouter>
              <DaftarGuru />
            </PrivateRouter>
          }
        />
        <Route
          path="/daftar-siswa"
          element={
            <PrivateRouter>
              <DaftarSiswa />
            </PrivateRouter>
          }
        />
        <Route
          path="/periksa"
          element={
            <PrivateRouter>
              <Periksa />
            </PrivateRouter>
          }
        />
        <Route
          path="/status-periksa/:id"
          element={
            <PrivateRouter>
              <StatusPeriksa />
            </PrivateRouter>
          }
        />
        <Route
          path="/edit-penanganan-pertama/:id"
          element={
            <PrivateRouter>
              <EditPenanganan />
            </PrivateRouter>
          }
        />
        <Route
          path="/edit-siswa/:id"
          element={
            <PrivateRouter>
              <EditSiswa />
            </PrivateRouter>
          }
        />
        <Route
          path="/edit-guru/:id"
          element={
            <PrivateRouter>
              <EditGuru />
            </PrivateRouter>
          }
        />
        <Route
          path="/edit-obat/:id"
          element={
            <PrivateRouter>
              <EditObat />
            </PrivateRouter>
          }
        />
        <Route
          path="/diagnosa"
          element={
            <PrivateRouter>
              <Diagnosa />
            </PrivateRouter>
          }
        />
        <Route
          path="/edit-diagnosa/:id"
          element={
            <PrivateRouter>
              <EditDiagnosa />
            </PrivateRouter>
          }
        />
        <Route
          path="/tindakan"
          element={
            <PrivateRouter>
              <Tindakan />
            </PrivateRouter>
          }
        />
        <Route
          path="/edit-tindakan/:id"
          element={
            <PrivateRouter>
              <EditTindakan />
            </PrivateRouter>
          }
        />
        <Route
          path="/penanganan"
          element={
            <PrivateRouter>
              <PenangananPertama />
            </PrivateRouter>
          }
        />
        <Route
          path="/penanganan/:id"
          element={
            <PrivateRouter>
              <EditPenanganan />
            </PrivateRouter>
          }
        />
        <Route
          path="/daftar-karyawan"
          element={
            <PrivateRouter>
              <DaftarKaryawan />
            </PrivateRouter>
          }
        />
        <Route
          path="/edit-karyawan/:id"
          element={
            <PrivateRouter>
              <EditKaryawan />
            </PrivateRouter>
          }
        />
      </Routes>
    </div>
  );
}

export default App;
