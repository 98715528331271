import axios from "axios";
import React, { useEffect, useState } from "react";
import { Form, InputGroup } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { API_AUTH } from "../../utils/BaseUrl";

function EditGuru() {
    const navigate = useNavigate();
    const param = useParams();
    const [namaGuru, setNamaGuru] = useState();
    const [tempatLahir, setTempatLahir] = useState();
    const [tanggalLahir, setTanggalLahir] = useState();
    const [alamat, setAlamat] = useState();

    const cancel = () => {
        navigate("/daftar-guru");
    }

    const namaGuruChangeHandler = (event) => {
        setNamaGuru(event.target.value);
    }
    const tempatLahirChangeHandler = (event) => {
        setTempatLahir(event.target.value);
    }
    const tanggalLahirChangeHandler = (event) => {
        setTanggalLahir(event.target.value);
    }
    const alamatChangeHandler = (event) => {
        setAlamat(event.target.value);
    }

    const submitActionHandler = async (event) => {
        event.preventDefault();

        await axios.put(`${API_AUTH}/api/guru/guru/` + param.id, {
            namaGuru: namaGuru,
            tempatLahir: tempatLahir,
            tanggalLahir: tanggalLahir,
            alamat: alamat,
        }).then(() => {
            Swal.fire({
                position: 'center',
                icon: 'success',
                title: 'Edit Success!!',
                showConfirmButton: false,
                timer: 1500
            });
            navigate("/daftar-guru")
        }).catch(error => {
            alert("Terjadi kesalahan: " + error);
        })
    };

    useEffect(() => {
        axios.get(`${API_AUTH}/api/guru/guru/` + param.id).then((response) => {
            const dataGuru = response.data;
            setNamaGuru(dataGuru.namaGuru);
            setTempatLahir(dataGuru.tempatLahir);
            setTanggalLahir(dataGuru.tanggalLahir);
            setAlamat(dataGuru.alamat);
        }).catch(error => {
            alert("Terjadi kesalahan Sir! " + error);
        });
    }, []);

    return (
        <div className="">
            <div className="flex justify-content-center container mx-auto pt-6 ml-4 mr-4">
                <div className="w-1/2 px-1 py-5 bg-white rounded-lg shadow">
                    <Form onSubmit={submitActionHandler} className="ml-4 mr-4">
                        <div className="mb-4 text-center">
                            <h1>Edit Guru</h1>
                        </div>
                        <div className="name mb-3">
                            <Form.Label>
                                <strong>Nama Guru</strong>
                            </Form.Label>
                            <InputGroup className="d-flex gap-3">
                                <Form.Control placeholder="Nama Guru" value={namaGuru} onChange={namaGuruChangeHandler} />
                            </InputGroup>
                        </div>
                        <div className="name mb-3">
                            <Form.Label>
                                <strong>Tempat Lahir</strong>
                            </Form.Label>
                            <InputGroup className="d-flex gap-3">
                                <Form.Control placeholder="Tempat Lahir" value={tempatLahir} onChange={tempatLahirChangeHandler} />
                            </InputGroup>
                        </div>
                        <div className="name mb-3">
                            <Form.Label>
                                <strong>Tanggal Lahir</strong>
                            </Form.Label>
                            <InputGroup className="d-flex gap-3">
                                <Form.Control type="date" value={tanggalLahir} onChange={tanggalLahirChangeHandler} />
                            </InputGroup>
                        </div>
                        <div className="name mb-3">
                            <Form.Label>
                                <strong>Alamat</strong>
                            </Form.Label>
                            <InputGroup className="d-flex gap-3">
                                <Form.Control placeholder="Alamat" value={alamat} onChange={alamatChangeHandler} />
                            </InputGroup>
                        </div>
                        <div className="d-flex justify-content-end align-items-center mt-2">
                            <button className="bg-red-600
                            text-white hover:bg-red-700 
                            focus:ring-4 focus:outline-none focus:ring-red-300 
                            font-medium rounded-lg text-md w-full sm:w-auto px-4 py-2 text-center" onClick={cancel}>
                                Batal
                            </button>
                            <div className="ms-2 me-2">||</div>
                            <button className="bg-green-500
                            text-white hover:bg-green-600 
                            focus:ring-4 focus:outline-none focus:ring-green-300 
                            font-medium rounded-lg text-md w-full sm:w-auto px-4 py-2 text-center" type="submit">Simpan</button>
                        </div>
                    </Form>
                </div>
            </div>
        </div >
    );
}

export default EditGuru;
