import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Smpn1 from "../assets/smpn1smg.png";
import { API_AUTH } from "../utils/BaseUrl";

export default function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const navigate = useNavigate();

  const login = async (e) => {
    e.preventDefault();

    try {
      const { data, status } = await axios.post(`${API_AUTH}/api/auth/login`, {
        username: username,
        password: password,
      });
      if (status === 200) {
        localStorage.setItem("id", data.id);
        localStorage.setItem("username", data.username);
        localStorage.setItem("token", data.token);
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Login Success!!",
          showConfirmButton: false,
          timer: 1500,
        });
        navigate("/dash");
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Login Error",
        text: "Password/Email yang anda masukan salah",
      });
    }
  };

  return (
    <div className="Login">
      <section className="bg-gray-50">
        <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
          <div className="flex items-center justify-center md:mt-0 sm:max-w-md xl:p-0 ">
            <a href="/" className="text-center items-center mb-6 no-underline">
              <span className="text-black text2xl lg:text-4xl font-medium">
                Sistem Aplikasi UKS
              </span>
              <img
                className="w-40 m-auto mt-4"
                src={Smpn1}
                alt={"Smp Negeri 1 Semarang"}
              />
            </a>
          </div>
          <div className="md:w-full lg:w-[25%] bg-white rounded-lg shadow">
            <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
              <h1 className="text-xl text-center font-bold leading-tight tracking-tight text-gray-900 md:text-2xl">
                Login
              </h1>
              <form className="space-y-4 md:space-y-6" onSubmit={login}>
                <div>
                  <label
                    htmlFor="username"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Email
                  </label>
                  <input
                    type="text"
                    defaultValue={username}
                    onChange={(e) => setUsername(e.target.value)}
                    name="username"
                    id="username"
                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5"
                    placeholder="Username"
                    required=""
                  />
                </div>
                <div>
                  <label
                    htmlFor="password"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Password
                  </label>
                  <input
                    type="password"
                    defaultValue={password}
                    onChange={(e) => setPassword(e.target.value)}
                    name="password"
                    id="password"
                    placeholder="Password"
                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5"
                    required=""
                  />
                </div>
                <button
                  type="submit"
                  className="w-full text-white bg-green-500 hover:bg-green-700 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                >
                  Login
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
