import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Form, InputGroup } from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";
import { API_AUTH } from "../../utils/BaseUrl";

function EditPenanganan() {
    const [namaPenanganan, setNamaPenanganan] = useState();

    const navigate = useNavigate();
    const param = useParams();

    const nameChangeHandler = (event) => {
        setNamaPenanganan(event.target.value);
    }

    const submitActionHandler = async (event) => {
        event.preventDefault();

        await axios.put(`${API_AUTH}/api/penanganan-pertama/` + param.id, {
            namaPenanganan: namaPenanganan,
        }, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            }
        }).then(() => {
            Swal.fire({
                position: 'center',
                icon: 'success',
                title: 'Edit Success!!',
                showConfirmButton: false,
                timer: 1500
            });
            navigate("/penanganan")
        }).catch(error => {
            alert("Terjadi kesalahan: " + error);
        })
    };

    const cancel = () => {
        navigate("/penanganan");
    }

    useEffect(() => {
        axios.get(`${API_AUTH}/api/penanganan-pertama/` + param.id, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            }
        }).then((response) => {
            const { namaPenanganan } = response.data;
            setNamaPenanganan(namaPenanganan);
        }).catch(error => {
            alert("Terjadi kesalahan Sir! " + error);
        });
    }, []);

    return (
        <div className="">
            <div className="flex justify-content-center container mx-auto pt-6 ml-4 mr-4">
                <div className="w-1/2 px-1 py-5 bg-white rounded-lg shadow">
                    <Form onSubmit={submitActionHandler} className="ml-4 mr-4">
                        <div className="mb-4 text-center">
                            <h1>Edit Penanganan</h1>
                        </div>
                        <div className="name mb-3">
                            <Form.Label>
                                <strong>Nama Penanganan</strong>
                            </Form.Label>
                            <InputGroup className="d-flex gap-3">
                                <Form.Control placeholder="Nama Penyakit" value={namaPenanganan} onChange={nameChangeHandler} />
                            </InputGroup>
                        </div>
                        <div className="d-flex justify-content-end align-items-center mt-2">
                            <button className="bg-red-600
                            text-white hover:bg-red-700 
                            focus:ring-4 focus:outline-none focus:ring-red-300 
                            font-medium rounded-lg text-md w-full sm:w-auto px-4 py-2 text-center" onClick={cancel}>
                                Batal
                            </button>
                            <div className="ms-2 me-2">||</div>
                            <button className="bg-green-500
                            text-white hover:bg-green-600 
                            focus:ring-4 focus:outline-none focus:ring-green-300 
                            font-medium rounded-lg text-md w-full sm:w-auto px-4 py-2 text-center" type="submit">Simpan</button>
                        </div>
                    </Form>
                </div >
            </div >
        </div >
    )
}

export default EditPenanganan;