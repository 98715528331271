import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import Sidebar from "../../components/Sidebar";
import { API_AUTH } from "../../utils/BaseUrl";

export default function StatusPeriksa() {
  const [listPenanganan, setListPenanganan] = useState([]);
  const [keluhan, setKeluhan] = useState();
  const [namaPasien, setNamaPasien] = useState();
  const [pasienStatusId, setPasienStatusId] = useState();

  const [catatan, setCatatan] = useState();

  // DIAGNOSA PENYAKIT
  const [diagnosaPenyakit, setDiagnosaPenyakit] = useState([]);
  const [diagnosaPenyakitId, setDiagnosaPenyakitId] = useState("");

  // KETERANGAN PASIEN
  const [penangananPertama, setPenangananPertama] = useState([]);
  const [penangananPertamaId, setPenangananPertamaId] = useState("");

  console.log(penangananPertamaId);

  // TINDAKAN
  const [keteranganPasien, setKeteraganPasien] = useState([]);
  const [keteraganPasienId, setKeteraganPasienId] = useState("");

  const navigate = useNavigate();
  const param = useParams();

  const tambahJumlah = async (e) => {
    e.preventDefault();
    if (
      !diagnosaPenyakitId ||
      !penangananPertamaId ||
      !catatan ||
      !keteraganPasienId
    )
      return;
    setListPenanganan((state) => [
      ...state,
      {
        diagnosaPenyakitId: JSON.parse(diagnosaPenyakitId),
        penangananPertamaId: penangananPertamaId,
        catatan: catatan,
        keteraganPasienId: JSON.parse(keteraganPasienId),
      },
    ]);
    setDiagnosaPenyakitId("");
    setPenangananPertamaId("");
    setCatatan("");
    setKeteraganPasienId("");
    setTimeout(() => {
      console.log(listPenanganan);
    }, 1000);
  };

  const PutPeriksa = async (e) => {
    e.preventDefault();
    const data = {
      keluhan: keluhan,
      listPenanganan: listPenanganan.map((e) => ({
        diagnosaPenyakitId: e.diagnosaPenyakitId.id,
        penangananPertamaId: e.penangananPertamaId,
        catatan: e.catatan,
        keteraganPasienId: e.keteraganPasienId.id,
      })),
    };
    console.log(listPenanganan);
    try {
      await axios.put(`${API_AUTH}/api/periksa/penanganan/${param.id}`, data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      Swal.fire({
        position: "center",
        icon: "success",
        title: "Add Success!!",
        showConfirmButton: false,
        timer: 1500,
      });
      navigate("/periksa");
    } catch (error) {
      console.log(error);
    }
  };

  const getDiagnosaPenyakit = async () => {
    await axios
      .get(`${API_AUTH}/api/diagnosa-penyakit`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setDiagnosaPenyakit(res.data);
      })
      .catch((error) => {
        alert("Error Has Caught: " + error);
      });
  };

  const getPenangananPertama = async () => {
    await axios
      .get(`${API_AUTH}/api/penanganan-pertama`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setPenangananPertama(res.data);
      })
      .catch((error) => {
        alert("Terjadi kesalahan " + error);
      });
  };

  const getTindakanPeriksa = async () => {
    await axios
      .get(`${API_AUTH}/api/keterangan-pasien`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setKeteraganPasien(res.data);
      })
      .catch((error) => {
        alert("Terjadi kesalahan " + error);
      });
  };

  const splice = (i) => {
    setListPenanganan((state) => [...state.slice(0, i), ...state.slice(i + 1)]);
  };

  useEffect(() => {
    axios
      .get(`${API_AUTH}/api/periksa/` + param.id, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        const { keluhan, namaPasien, pasienStatusId } = response.data;
        setKeluhan(keluhan);
        setNamaPasien(namaPasien);
        setPasienStatusId(pasienStatusId?.name);
      })
      .catch((err) => {
        alert(err);
      });
    getDiagnosaPenyakit();
    getPenangananPertama();
    getTindakanPeriksa();
  }, []);

  return (
    <div className="relative min-h-screen md:flex" data-dev-hint="container">
      <Sidebar />
      <main id="content" className="flex-1 p-6 lg:px-8">
        <div className="container mx-auto">
          <div className="text-center py-2 bg-green-400 text-white">
            Periksa Pasien : <span className="uppercase">{namaPasien}</span>
          </div>
          <div className="w-full px-4 bg-white shadow border border-gray-900">
            <form onSubmit={tambahJumlah}>
              <div className="grid gap-6 mb-2 mt-2 md:grid-cols-2">
                <div>
                  <label
                    htmlFor="name"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Nama Pasien
                  </label>
                  <input
                    value={namaPasien}
                    disabled
                    type="text"
                    id="name"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  />
                </div>
                <div>
                  <label
                    htmlFor="status"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Status Pasien
                  </label>
                  <input
                    value={pasienStatusId}
                    disabled
                    type="text"
                    id="status"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  />
                </div>
              </div>
              <div className="mb-2">
                <label
                  htmlFor="keluhan"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Keluhan Pasien
                </label>
                <textarea
                  disabled
                  value={keluhan}
                  id="keluhan"
                  className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                />
              </div>
              <div className="grid gap-6 mb-2 mt-2 md:grid-cols-4">
                <div>
                  <label
                    htmlFor="penyakit"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Penyakit Pasien
                  </label>
                  <select
                    value={diagnosaPenyakitId}
                    onChange={(e) => setDiagnosaPenyakitId(e.target.value)}
                    id="penyakit"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  >
                    <option className="hidden" selected="">
                      Pilih Penyakit
                    </option>
                    {diagnosaPenyakit.map((penyakit, index) => (
                      <option key={index} value={JSON.stringify(penyakit)}>
                        {penyakit.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div>
                  <label
                    htmlFor="keterangan"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Penanganan Pertama
                  </label>
                  <select
                    value={penangananPertamaId}
                    onChange={(e) => setPenangananPertamaId(e.target.value)}
                    id="keterangan"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  >
                    <option className="hidden" selected="">
                      Pilih Penanganan
                    </option>
                    {penangananPertama.map((keterangan, index) => (
                      <option key={index} value={keterangan.id}>
                        {keterangan.namaPenanganan}
                      </option>
                    ))}
                  </select>
                </div>
                <div>
                  <label
                    htmlFor="penanganan"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Tindakan
                  </label>
                  <select
                    value={keteraganPasienId}
                    onChange={(e) => setKeteraganPasienId(e.target.value)}
                    id="penanganan"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  >
                    <option className="hidden" selected="">
                      Pilih Tindakan
                    </option>
                    {keteranganPasien.map((act, index) => (
                      <option key={index} value={JSON.stringify(act)}>
                        {act.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div>
                  <label
                    htmlFor="Catatan"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Catatan
                  </label>
                  <div className="flex flex-wrap justify-between">
                    <div>
                      <input
                        value={catatan}
                        onChange={(e) => setCatatan(e.target.value)}
                        type="text"
                        id="Catatan"
                        className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      />
                    </div>
                    <div>
                      <button
                        type="submit"
                        className="rounded-lg text-white mb-2 bg-green-500 hover:bg-green-600 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium text-sm w-11 h-full sm:w-auto px-2.5 text-center"
                      >
                        Tambah
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>

            <div className="overflow-x-auto relative">
              <table className="w-full my-4 text-sm text-left text-gray-500">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                  <tr>
                    <th scope="col" className="py-3 px-6">
                      NO
                    </th>
                    <th scope="col" className="py-3 px-6">
                      Penyakit Pasien
                    </th>
                    <th scope="col" className="py-3 px-6">
                      Tindakan
                    </th>
                    <th scope="col" className="py-3 px-6">
                      Catatan
                    </th>
                    <th scope="col" className="py-3 px-6 text-center">
                      Aksi
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {listPenanganan.map((list, i) => (
                    <tr key={i} className="bg-white border-b">
                      <td className="py-4 px-6">{i + 1}</td>
                      <td className="py-4 px-6">
                        {list?.diagnosaPenyakitId?.name}
                      </td>
                      <td className="py-4 px-6">
                        {list?.keteraganPasienId?.name}
                      </td>
                      <td className="py-4 px-6">{list?.catatan}</td>
                      <td className="py-4 px-6 text-center">
                        <button onClick={() => splice(i)} className="w-full">
                          X
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="w-full my-6 px-4 bg-white shadow border border-gray-900">
            <div className="grid md:grid-cols-2">
              <div></div>
              <div className="my-2">
                <button
                  onClick={PutPeriksa}
                  className="float-right text-white bg-green-500 hover:bg-green-600 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center mr-2"
                >
                  Selesai
                </button>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}
